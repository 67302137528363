// src/Componat/Login.js
import React, { useContext, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import FirebaseContext from '../FirebaseContext.js';
import '/Users/hirenmasaliya/ReactDemo/pricelist/src/Css/Login.css';

const Login = () => {
  const { firebase } = useContext(FirebaseContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState('signup');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSignUp = async () => {
    window.location.href = "/signup"
  }

  const handleLogin = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      if (userCredential.user && userCredential.user.emailVerified) {
        // If user is logged in and email is verified, navigate to the desired page
        navigate('/addcompany');
      } else {
        setError("User not logged in or email not verified.");

      }
    } catch (error) {
      setError(error.message);
      console.error(error.message);
    }finally{
      setLoading(false);
    }
  };

  return (
    <div className='boxdiv'>
      <div className='box'>
        <h1>Login</h1>
        <div className='coll'>
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <Link to='/' className='txtright' onClick={() => handlePageChange('forgetPassword')}>Forget Password ?</Link>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button className='btn-space' onClick={handleLogin}>Login</button>
          Don't have an account? <Link to='/' onClick={handleSignUp}>Sign Up</Link>
        </div>
      </div>
      {loading ? (
        <div className="loader-container">
          <img src={require("/Users/hirenmasaliya/ReactDemo/pricelist/src/Images/loading.png")} alt="Loading..." />
        </div>
      ) : (
        <>
        </>
      )}
      <div className='loginImg'>
        <img src={require("/Users/hirenmasaliya/ReactDemo/pricelist/src/Images/Login.jpeg")} alt="Login" />
      </div>


    </div>
  );
};

export default Login;
