import { getDatabase, onValue, ref } from "firebase/database";
import React,{ useEffect,useState } from "react";
import '/Users/hirenmasaliya/ReactDemo/pricelist/src/Css/ShowTitles.css';


function ShowTitles({companyRef,userId}){

    const [ShowTitles,setTitles] = useState([]);

    useEffect(() => {
        const db = getDatabase();
    
        if (userId) {
            const titleRef = ref(db, `users/${userId}/companies/${companyRef}/titles`);
    
            const unsubscribeData = onValue(titleRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const dataArray = Object.keys(data).map((key) => ({ id: key, ...data[key] }));
                    setTitles(dataArray);
                } else {
                    console.error('Invalid data structure from Firebase:', data);
                    // Handle potential errors or display a user-friendly message
                }
                
            });
    
            return () => unsubscribeData();
        }
    }, [userId, companyRef]); // Add dependencies to control when the effect runs
    

    return(
       <div className="showcompany">
            <h2>TITLES LIST</h2>
            <div className="scroll">
                {ShowTitles.length > 0 ?(
                    <div>
                        <ul>
                            {ShowTitles.map((titles) => (
                                <div className="textcard" key={titles.id}>
                                    <input type="checkbox"  />
                                    <h4 className="card">{titles.titleName}</h4>  
                                </div>
                            ))}
                        </ul>
                    </div>
                ):(
                    <p>No Titles found.</p>
                )}
            </div>
       </div>
    );
}

export default ShowTitles;