import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, remove } from "firebase/database";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '/Users/hirenmasaliya/ReactDemo/pricelist/src/App.css';

function ShowCompanyDetails() {
    const [userId, setUserId] = useState(null);
    const [companyDetails, setCompanyDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCompanies, setSelectedCompanies] = useState(new Set());

    const auth = getAuth();



    useEffect(() => {
        const db = getDatabase();

        const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
            } else {
                setUserId(null);
            }
        });

        if (userId) {
            const companiesRef = ref(db, `users/${userId}/companies`);

            const unsubscribeData = onValue(companiesRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const dataArray = Object.keys(data).map((key) => ({ id: key, ...data[key] }));
                    setCompanyDetails(dataArray);
                } else {
                    setCompanyDetails([]);
                }
                setLoading(false);
            });

            return () => {
                unsubscribeAuth();
                unsubscribeData();
            };
        } else {
            setLoading(false);
        }
    }, [userId, auth]);

    const handleCheckboxChange = (e, companyId) => {
        try {
            const isChecked = e.target.checked;
            if (isChecked) {
                setSelectedCompanies(new Set(selectedCompanies.add(companyId)));
            } else {
                setSelectedCompanies(new Set(selectedCompanies.delete(companyId)));
            }
        } catch (error) {
            console.log(error);
        }

    };

    const handleDeleteSelected = async () => {
        if (selectedCompanies.size === 0) {
            console.log("No companies selected for deletion.");
            return;
        }

        try {
            const db = getDatabase();
            await Promise.all(Array.from(selectedCompanies).map(async (companyId) => {
                const companyRef = ref(db, `users/${userId}/companies/${companyId}`);
                try {
                    await remove(companyRef);
                    console.log("Company deleted successfully:", companyId);
                } catch (error) {
                    console.error("Error deleting company", companyId, error);
                }
            }));

            setSelectedCompanies(new Set());
            // Optionally update UI or reload data
        } catch (error) {
            console.error("Overall error during deletion:", error);
            // Handle overall errors
        }
    };

    return (
        <div className="showcompany">
            <h2>Company Details</h2>
            {loading ? (
                <div className="loader-container">
                    <img src={require("/Users/hirenmasaliya/ReactDemo/pricelist/src/Images/loading.png")} alt="Loading..." />
                </div>
            ) : (
                <div className="scroll">
                    {companyDetails.length > 0 ? (
                        <div>
                            <ul>
                                {companyDetails.map((company) => (
                                    <div className="card" key={company.id}>
                                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, company.id)} />
                                        <img src={company.logoURL} alt="Images" />
                                        <div>
                                            <h5>{company.companyName.toUpperCase()}</h5>


                                        </div>
                                    </div>
                                ))}
                            </ul>
                            <button onClick={handleDeleteSelected}>Delete

                            </button>
                        </div>
                    ) : (
                        <p>No companies found.</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default ShowCompanyDetails;
