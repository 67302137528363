import React, { useState } from "react";
import { getDatabase, ref, push, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
//import { AdMobBanner } from 'react-admob';
import ShowCompanyDetails from './ShowCompanyDetails';
import '/Users/hirenmasaliya/ReactDemo/pricelist/src/Css/AddCompanyDetatil.css';

const AddCompanyDetails = () => {
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [cname, setCname] = useState('');
  const [mno, setMno] = useState('');
  const [file, setFile] = useState(null);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const uploadFile = async () => {
    const user = auth.currentUser;

    if (user && cname !== "" && address !== "" && email !== "" && mno !== "" && file) {
      setLoading(true);

      try {
        const userId = user.uid;
        const db = getDatabase();
        const storage = getStorage();

        const companiesRef = ref(db, `users/${userId}/companies`);
        const logosRef = storageRef(storage, 'logos');

        const fileId = Date.now().toString();
        const fileRef = storageRef(logosRef, `${fileId}_${file.name}`);

        await uploadBytes(fileRef, file);
        const snapshot = await getDownloadURL(fileRef);
        const newCompanyRef = push(companiesRef);
        const newCompanyId = newCompanyRef.key;

        set(newCompanyRef, {
          id: newCompanyId,
          companyName: cname,
          address: address,
          email: email,
          mobileNumber: mno,
          logoURL: snapshot,
        });

        setCname("");
        setEmail("");
        setMno("");
        setFile(null);
        setAddress("");

        console.log("Data added successfully!");
      } catch (error) {
        console.error("Error adding data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Error: Please fill in all the fields and select a file.");
    }
  };

  return (
    <div className="main-content" style={{ display: 'flex', flexDirection: 'row' }}>

      <div>
          <h2 className="heading">Add Company Details</h2>
        <form className="space">
          <div className="box">
            <input placeholder="Company Name" type='text' value={cname} onChange={(e) => setCname(e.target.value)} />
            <textarea placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
            <input placeholder="Email" type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
            <input placeholder="Mobile No" type='text' value={mno} onChange={(e) => setMno(e.target.value)} />
            <input type='file' onChange={handleFileChange} />
          </div>
          {loading ? (
            <div className="loader-container">
              <img src={require("/Users/hirenmasaliya/ReactDemo/pricelist/src/Images/loading.png")} alt="Loading..." />
            </div>
          ) : (
            <button type="button" onClick={uploadFile}>Add</button>
          )}
        </form>
      </div>


      <ShowCompanyDetails />
    </div>
  );
}

export default AddCompanyDetails;
