import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, push, onValue } from 'firebase/database';
import { getAuth } from 'firebase/auth'; // Import getAuth function from firebase/auth
import '/Users/hirenmasaliya/ReactDemo/pricelist/src/App.css';
import ShowTitles from './ShowTitles';

const AddTitle = () => {
  const auth = getAuth(); // Initialize Firebase Authentication
  const [companies, setCompanies] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [titleName, setTitleName] = useState('');
  const user = auth.currentUser;
  const userId = user.uid;

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        if (userId) {
          const db = getDatabase();
          const companiesRef = ref(db, `users/${userId}/companies`);

          const unsubscribeData = onValue(companiesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const companiesList = Object.keys(data).map((key) => ({
                id: key,
                ...data[key],
              }));
              setCompanies(companiesList); // Set companies state
              setCompanyDetails(companiesList); // Set companyDetails state as well
              if (companiesList.length > 0) {
                setSelectedCompany(companiesList[0].id);
              }
            } else {
              console.log("No companies found.");
            }
          });

          return () => {
            // Unsubscribe from onValue listener
            unsubscribeData();
          };
        } else {
          console.log("User not logged in.");
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, [userId]); // Include userId in the dependency array

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitleName(e.target.value);
  };

  const handleSaveClick = async () => {
    if (!userId || !selectedCompany || !titleName) {
      console.error("Please select a company and enter a title name.");
      return;
    }

    try {
      const db = getDatabase();
      const titlesRef = ref(db, `users/${userId}/companies/${selectedCompany}/titles`);
      const newTitleRef = push(titlesRef);
      const newTitleKey = newTitleRef.key;

      // Update the newTitleRef to point to the specific title
      const specificTitleRef = ref(db, `users/${userId}/companies/${selectedCompany}/titles/${newTitleKey}`);

      await set(specificTitleRef, { id: newTitleKey, titleName: titleName });

      console.log("Title added successfully!");
      setTitleName(''); // Clear title input after saving
    } catch (error) {
      console.error("Error adding title:", error);
    }
  };


  return (
    <div className="main-content" style={{ display: 'flex', flexDirection: 'row' }}>
      <div className='divbox'>
        <h2 className='heading'>ADD TITLES</h2>
        <form className='space'>
          <div className='box'>
            <label>Select Company:</label>
            <select value={selectedCompany} onChange={handleCompanyChange}>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>{company.companyName}</option>
              ))}
            </select>
            <label>Title Name:</label>
            <input placeholder='Title Name' type="text" value={titleName} onChange={handleTitleChange} />
            <button onClick={handleSaveClick}>Add</button>
          </div>

        </form>
      </div>
      <ShowTitles companyRef={selectedCompany} userId={userId}/>
    </div>
  );
};

export default AddTitle;
