import React, { useEffect, useState } from 'react';
import { Link, Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import './App.css';
import AddCompanyDetails from './Componat/AddCompanyDetails.js';
import AddTitle from './Componat/AddTitle';
import Login from './Componat/Login';
import SignUp from './Componat/SignUp';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import AuthenticatedLayout from './Componat/AuthenticatedLayout.js';
import AddProducts from './Componat/AddProducts.js';

function App() {
  const [user, setUser] = useState(null);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setInitializing(false); // Allow rendering after authentication state is settled
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            initializing ? (
              <></> // Render nothing while initializing
            ) : user ? (
              <Navigate to="/addcompany" />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>

      {user && !initializing && ( // Only render for authenticated users
        <AuthenticatedLayout user={user}>
          <Routes>
            <Route path="/" element={<Navigate to="/addcompany" replace />} />
            <Route path="/addtitle" element={<AddTitle />} />
            <Route path='/addProducts' element={<AddProducts />}/>
            <Route path="/addcompany" element={<AddCompanyDetails />} />
          </Routes>
        </AuthenticatedLayout>
      )}
    </Router>
  );
}

export default App;
