import { getAuth } from "firebase/auth";
import { getDatabase, ref, push, set, onValue } from 'firebase/database';
import { useEffect, useState } from "react";

const AddProducts = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user.uid;

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [titles, setTitles] = useState([]);
    const [titleInput, setTitleInput] = useState('');
    const [titleInputs, setTitleInputs] = useState({}); // State to store input values for each title

    const fetchTitles = async (selectedCompanyId) => {
        try {
            if (userId && selectedCompanyId) {
                const db = getDatabase();
                const titleRef = ref(db, `users/${userId}/companies/${selectedCompanyId}/titles`);

                const unsubscribeData = onValue(titleRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const titlesList = Object.keys(data).map((key) => ({
                            id: key,
                            ...data[key],
                        }));
                        setTitles(titlesList);
                    } else {
                        console.error("No Titles found.");
                        setTitles([]); // Reset titles if no titles found
                    }
                });

                return () => unsubscribeData();
            }
        } catch (error) {
            console.error("Error fetching titles:", error);
        }
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                if (userId) {
                    const db = getDatabase();
                    const companiesRef = ref(db, `users/${userId}/companies`);

                    const unsubscribeData = onValue(companiesRef, (snapshot) => {
                        const data = snapshot.val();
                        if (data) {
                            const companiesList = Object.keys(data).map((key) => ({
                                id: key,
                                ...data[key],
                            }));
                            setCompanies(companiesList);
                            if (companiesList.length > 0) {
                                setSelectedCompany(companiesList[0].id);
                            }
                        } else {
                            console.log("No companies found.");
                        }
                    });

                    return () => {
                        unsubscribeData();
                    };
                } else {
                    console.log("User not logged in.");
                }
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
        };
        fetchCompanies();
    }, [userId]);

    useEffect(() => {
        fetchTitles(selectedCompany);
    }, [selectedCompany]);

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);
    };

    const handleAddButtonClick = () => {
        if (!titleInput) {
            console.error("Title input is empty");
            return;
        }

        const db = getDatabase();
        const titleRef = ref(db, `users/${userId}/companies/${selectedCompany}/titles`);
        const newTitleRef = push(titleRef);

        set(newTitleRef, {
            titleName: titleInput
            // Add other properties if needed
        }).then(() => {
            console.log("Title added successfully");
            setTitleInput('');
        }).catch((error) => {
            console.error("Error adding title:", error);
        });
    };

    const handleInputChange = (e, titleId) => {
        const value = e.target.value;
        setTitleInputs(prevState => ({
            ...prevState,
            [titleId]: value
        }));
    };

    const handleSaveButtonClick = () => {
        // Save the input values for each title to Firebase
        const db = getDatabase();
        titles.forEach(title => {
            const input = titleInputs[title.id];
            if (input !== undefined && input.trim() !== '') {
                const titleInputRef = ref(db, `users/${userId}/companies/${selectedCompany}/titles/${title.id}/input`);
                const titleInputReference = push(titleInputRef);
                const tittleInputKey = titleInputReference.key;

                const specificTitleInputRef = ref(db, `users/${userId}/companies/${selectedCompany}/titles/${title.id}/input/${tittleInputKey}`);

                set(specificTitleInputRef, { id: tittleInputKey, inputName: input}).then(() => {
                    setTitleInputs('');
                }).catch((error) => {
                    console.error(`Error saving input for title '${title.titleName}':`, error);
                });
            }
        });
    };

    return (
        <div className="main-content" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className='divbox'>
                <h2 className='heading'>ADD PRODUCTS</h2>
                <form className='space'>
                    <div className='box'>
                        <label>Select Company:</label>
                        <select value={selectedCompany} onChange={handleCompanyChange}>
                            {companies.map((company) => (
                                <option key={company.id} value={company.id}>{company.companyName}</option>
                            ))}
                        </select>

                        <div>
                            {titles.map((title) => (
                                <div key={title.id}>
                                    <h4>{title.titleName} ( Title )</h4>
                                    <input
                                        placeholder={title.titleName}
                                        type="text"
                                        value={titleInputs[title.id] || ''}
                                        onChange={(e) => handleInputChange(e, title.id)}
                                    />
                                </div>
                            ))}
                            <button type="button" onClick={handleSaveButtonClick}>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddProducts;
