import React from 'react';
import SideMenu from './SideMenu'; // Assuming SideMenu is defined elsewhere
 // Assuming ShowCompanyDetails is defined elsewhere
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

const AuthenticatedLayout = ({ children, user }) => { // Pass user prop for SideMenu

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      window.location.href = '/login';
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
      <SideMenu user={user} onLogout={handleLogout} />
      <main style={{ flex: 1 }}>{children}</main>
    </div>
  );
};

export default AuthenticatedLayout;
