import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Route, Navigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import AddCompanyDetails from './AddCompanyDetails';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState('signup');
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSignUp = async () => {
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await sendEmailVerification(user);

      const database = getDatabase();
      const userRef = ref(database, `users/${user.uid}`);
      set(userRef, {
        email: user.email,
        password: password,
      });

      window.alert('User Add Successfully');
    } catch (error) {
      setError(error.message);
      console.error('Error signing up:', error);
    }
  };

  const handleLogin = async () => {
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        // Redirect to AddCompanyDeatils page upon successful login
        navigate('/addcompanydetails');
      } else {
        window.alert("User not verified. Please check your email for verification.");
      }
    } catch (error) {
      setError(error.message);
      console.error(error.message);
    }
  };

  if (user) {
    // If user is logged in, redirect to AddCompanyDeatils page
    return <Navigate to="/addcompany" onChange={() => handlePageChange('addcompanydetails')} />;
  }

  return (
    <div className='boxdiv'>
      <div className='loginImg'>
        <img src={require("/Users/hirenmasaliya/ReactDemo/pricelist/src/Images/Login.jpeg")} alt="Login" />
      </div>
      <div className='box'>
        <h2>{currentPage === 'signup' ? 'Sign Up' : 'Login'}</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}

        <div className='coll'>
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <input type="password" placeholder='Conform Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
          <button onClick={handleSignUp}>Sign Up</button>
          Already have an Account? <Link to='/login' onClick={() => handlePageChange('login')}>Login</Link>
        </div>
      </div>
      
      
      {currentPage === 'addcompany' && <AddCompanyDetails />}

    </div>


  );
};

export default SignUp;
