import React, { useEffect, useState } from 'react';
import { Link, Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';

const SideMenu = ({ user, onLogout }) => {
  const [currentPage, setCurrentPage] = useState('addcompany');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLogout = () => {
    onLogout();
    window.location.href = "/login"
  };

  const Login = () => {
    window.location.href = "/signup"
  }

  return (
    <>
      <div className="side-menu">
        <h3>PRICELIST MACKER</h3>
        <ul>
          <li className={currentPage === 'addcompany' ? 'selected' : ''}>
            <Link to="/addcompany" onClick={() => handlePageChange('addcompany')}>
              Add Company Details
            </Link>
          </li>
          <li className={currentPage === 'addtitle' ? 'selected' : ''}>
            <Link to="/addtitle" onClick={() => handlePageChange('addtitle')}>
              Add Titles
            </Link>
          </li>
          <li className={currentPage === 'addProducts' ? 'selected' : ''}>
            <Link to="/addProducts" onClick={() => handlePageChange('addProducts')}>
              Add Products
            </Link>
          </li>
          <li className={currentPage === 'about' ? 'selected' : ''}>
            <Link to="/about" onClick={() => handlePageChange('about')}>
              About
            </Link>
          </li>
          {user ? (
            // If user is logged in, show logout button
            <button onClick={handleLogout}>Logout</button>
          ) : (
            // If user is not logged in, show login link
            <button onClick={Login}>Login</button>
          )}
        </ul>
      </div>
    </>
  );
};

export default SideMenu;