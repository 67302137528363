// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZqQqdPftxvF9ePgM4BX1IYLop10Lc1r8",
  authDomain: "pricelist-maker-d89cb.firebaseapp.com",
  databaseURL: "https://pricelist-maker-d89cb-default-rtdb.firebaseio.com",
  projectId: "pricelist-maker-d89cb",
  storageBucket: "pricelist-maker-d89cb.appspot.com",
  messagingSenderId: "668331986546",
  appId: "1:668331986546:web:0d4b851dfa09a8d36d7ceb",
  measurementId: "G-ZPLP71T04S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { firebaseConfig };